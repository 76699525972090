import axios from "axios";
import { addToast } from "@budzetrodzinki/Toaster/src/composables/toasterActions";
import { ToasterTypes } from "@budzetrodzinki/Toaster/src/constants/toasterTypes";

export const _axios = () => {
  const getCookie = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts: string[] = value.split(`; ${name}=`);
    if (parts.length && parts.length === 2) {
      const lastElement = parts.pop();
      if (lastElement && lastElement.split(";").length) {
        return lastElement.split(";").shift() as string;
      }
    }
    return "";
  };

  const baseURL = process.env.VUE_APP_API_URL;

  const axiosClient = axios.create({
    baseURL,
    headers: {
      "X-CSRFToken": getCookie("csrftoken"),
    },
    withCredentials: true,
  });

  axiosClient.interceptors.request.use(
    (config) => {
      config.headers["X-CSRFToken"] = getCookie("csrftoken");
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosClient.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      let err_message = error.message;

      if (error?.response?.data?.detail)
        err_message = error.response.data.detail;

      if (err_message) {
        addToast({
          status: ToasterTypes.error,
          message: err_message,
        });
      }

      return Promise.reject(error);
    }
  );
  return {
    axiosClient,
    getCookie,
  };
};
