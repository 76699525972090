import { _axios } from "@/services/api";
import { AxiosResponse } from "axios";
import { LocationInterface } from "../types/ReceiptAcceptInterfaces";
import { ReceiptTextInterface } from "../types/ReceiptInterfaces";

const { axiosClient } = _axios();
const path = "constructor/";

export const submitReceipt = (receipt_uuid: string) => {
  return axiosClient.post<string>(`${path}submit-receipt/`, {
    receipt_uuid,
  });
};

export const getExistingTransactions = (
  receipt_uuid: string
): Promise<AxiosResponse> => {
  return axiosClient.get(`${path}existing-transactions/${receipt_uuid}/`);
};

export const getPreviewTransaction = (
  receipt_uuid: string
): Promise<AxiosResponse> => {
  return axiosClient.get(`${path}receipt-review/${receipt_uuid}/`);
};

export const mergeReceiptToTransaction = (
  receipt_uuid: string,
  transaction_uuid: string
) => {
  return axiosClient.post<string>(`${path}merge-transaction/`, {
    receipt_uuid,
    transaction_uuid,
  });
};

export const getReceiptLocation = (receipt_uuid: string) => {
  return axiosClient.get<LocationInterface>(
    `${path}location-assign/${receipt_uuid}/`
  );
};

export const attachLocationTo = (
  place_uuid: string | undefined,
  receipt_uuid: string
): Promise<AxiosResponse> => {
  return axiosClient.patch(`${path}location-assign/${receipt_uuid}/`, {
    place_uuid,
  });
};

export const getReceiptText = (receipt_uuid: string) => {
  return axiosClient.get<ReceiptTextInterface[]>(
    `${path}receipt-text/${receipt_uuid}/`
  );
};
