import { RouteRecordRaw } from "vue-router";

import ReceiptAcceptView from "@/pages/constructor-accept/ReceiptAcceptView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/login",
    meta: {
      guestOnly: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      guestOnly: true,
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "@/pages/auth/LoginView.vue"),
  },
  {
    path: "/registration",
    name: "Registration",
    meta: {
      guestOnly: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "registration" */ "../pages/auth/RegistrationView.vue"
      ),
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    meta: {
      guestOnly: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "ForgotPassword" */ "@/pages/auth/ForgotPassword.vue"
      ),
  },
  {
    path: "/email-sent",
    name: "EmailSent",
    meta: {
      guestOnly: true,
    },
    component: () =>
      import(/* webpackChunkName: "EmailSent" */ "@/pages/auth/EmailSent.vue"),
  },
  {
    path: "/dobro-wraca/:token/",
    name: "RestoreProfile",
    meta: {
      guestOnly: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "RestoreProfile" */ "@/pages/auth/RestoreProfile.vue"
      ),
  },
  {
    path: "/new-password/:token",
    name: "NewPassword",
    meta: {
      guestOnly: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "new-password" */ "../pages/auth/NewPassword.vue"
      ),
  },
  {
    path: "/confirm-profile-change/:token",
    name: "ConfirmProfileChange",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "ConfirmProfileChange" */ "@/pages/auth/ConfirmProfileChange.vue"
      ),
  },
  {
    path: "/confirm-password-change/:token",
    name: "ConfirmPasswordChange",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "ConfirmPasswordChange" */ "@/pages/auth/ConfirmPasswordChange.vue"
      ),
  },
  {
    path: "/dashboard",
    redirect: "/dashboard/accounts",
    name: "Dashboard",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ "@/layouts/MainLayout.vue"),
    children: [
      {
        path: "accounts",
        name: "Accounts",
        component: () =>
          import(
            /* webpackChunkName: "Accounts" */ "@/pages/account-list/AccountsPage.vue"
          ),
      },
      {
        path: "accounts/:id/:page?",
        name: "AccountDetails",
        component: () =>
          import(
            /* webpackChunkName: "AccountImports" */ "../pages/account-detail/AccountDetails.vue"
          ),
      },
      {
        path: "accounts/:account_uuid/imports",
        name: "AccountImports",
        component: () =>
          import(
            /* webpackChunkName: "AccountImports" */ "@/pages/account-imports/AccountImports.vue"
          ),
      },
      {
        path: "accounts/:account_uuid/imports/:import_uuid",
        name: "AccountImportDetail",
        component: () =>
          import(
            /* webpackChunkName: "AccountImports" */ "@/pages/account-import-detail/AccountImportDetail.vue"
          ),
      },
      {
        path: "periods/:page?",
        name: "Periods",
        component: () =>
          import(
            /* webpackChunkName: "PeriodList" */ "@/pages/period-list/PeriodList.vue"
          ),
      },
      {
        path: "periods/detail/:period_uuid",
        name: "PeriodDetails",
        component: () =>
          import(
            /* webpackChunkName: "Period" */ "../pages/period-detail/PeriodDetails.vue"
          ),
      },
      {
        path: "places/:page?",
        name: "Places",
        component: () =>
          import(
            /* webpackChunkName: "Places" */ "@/pages/places/PlacesView.vue"
          ),
      },
      {
        path: "products/:page?",
        name: "Products",
        component: () =>
          import(
            /* webpackChunkName: "Products" */ "@/pages/products/ProductsView.vue"
          ),
      },
      {
        path: "future-transaction",
        name: "FutureTransaction",
        component: () =>
          import(
            /* webpackChunkName: "FutureTransaction" */ "@/pages/future-transaction/FutureTransaction.vue"
          ),
      },
      {
        path: "tags",
        name: "Tags",
        component: () =>
          import(/* webpackChunkName: "Tags" */ "@/pages/tags/TagsView.vue"),
      },
      {
        path: "settings",
        name: "Settings",
        component: () =>
          import(
            /* webpackChunkName: "SearchPage" */ "../pages/settings/SettingsPage.vue"
          ),
      },
      {
        path: "checks/:page?",
        name: "Checks",
        component: () =>
          import(
            /* webpackChunkName: "Checks" */ "../pages/checks/ChecksView.vue"
          ),
      },
      {
        path: "search-page/:page?",
        name: "SearchPage",
        component: () =>
          import(
            /* webpackChunkName: "SearchPage" */ "../pages/search/SearchPage.vue"
          ),
      },
    ],
  },
  {
    path: "/constructor/:receipt_uuid",
    name: "ReceiptConstructor",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "ReceiptConstructorView" */ "../pages/constructor/ReceiptConstructorView.vue"
      ),
  },
  {
    path: "/constructor-accept/:receipt_uuid",
    name: "ReceiptAccept",
    meta: {
      requiresAuth: true,
    },
    component: ReceiptAcceptView,
  },
];

export default routes;
