import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "@/scss/styles.scss";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { PiniaSharedState } from "pinia-shared-state";
import i18n from "@/plugins/i18n";
import { useAuthStore } from "./pages/auth/store/authStore";
import { clickedOutsideDirective } from "@budzetrodzinki/popup/utils/directives";
import { authenticate } from "@budzetrodzinki/BackendCommunication/services/authentication/authenticate";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
pinia.use(PiniaSharedState({ enable: true, initialize: true, type: "native" }));

const app = createApp(App);

app.use(pinia).use(i18n).use(router).mount("#app");

app.provide("router", router);

const authStore = useAuthStore();

router.beforeEach((to, from, next) => {
  function is_authenticated() {
    if (to.matched.some((route) => route.meta.requiresAuth)) {
      return next();
    }

    if (to.matched.some((route) => route.meta.guestOnly)) {
      return next({
        name: "Dashboard",
      });
    }
  }

  function unauthenticated() {
    if (to.matched.some((route) => route.meta.requiresAuth)) {
      return next({
        name: "Login",
      });
    }

    if (to.matched.some((route) => route.meta.guestOnly)) {
      return next();
    }
  }

  if (authStore.isAuthorized.value) {
    authenticate()
      .then(() => {
        is_authenticated();
      })
      .catch(() => {
        authStore.isAuthorized.value = false;
        unauthenticated();
      });
  } else {
    unauthenticated();
  }
});

app.directive("click-outside", clickedOutsideDirective);
