import { _axios } from "@/services/api";

const { axiosClient } = _axios();
const path = "financial/";

export const newCheck = (payload: FormData) => {
  return axiosClient.post<string>(`${path}gallery/`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteCheck = (uuid: string) => {
  return axiosClient.delete<string>(`${path}gallery/`, {
    data: {
      uuid,
    },
  });
};

export const processedReceiptDetails = (receipt_uuid: string) => {
  return axiosClient.post<string>(`${path}receipt-ocr/`, {
    receipt_uuid,
  });
};
