import { AxiosResponse } from "axios";
import { _axios } from "@/services/api";
import { TagInterface } from "@budzetrodzinki/BackendCommunication/services/tag/domain/entities";
import { PurchaseInterface } from "@budzetrodzinki/BackendCommunication/services/purchase/domain/entities";
import { ProductItemInterface } from "@budzetrodzinki/BackendCommunication/services/components/product_selection/types/ProductInterface";
import {
  ConstructorDetailsInterface,
  ReceiptDetailsInterface,
} from "@/types/CheckInterface";
import { SuggestedPurchaseInterface } from "@/types/pages/ConstructorInterfaces";

const { axiosClient } = _axios();
const path = "constructor/";

export const getPurchases = (receipt_uuid: string) => {
  return axiosClient.get<SuggestedPurchaseInterface[]>(
    `${path}purchase/${receipt_uuid}`
  );
};
export const createPurchase = (
  receipt_uuid: string,
  product_uuid: string,
  purchase: PurchaseInterface
) => {
  return axiosClient.post<string>(`${path}purchase/`, {
    receipt_uuid,
    product_uuid,
    ...purchase,
  });
};

export const deletePurchase = (
  purchase_uuid: string
): Promise<AxiosResponse> => {
  return axiosClient.delete(`${path}purchase/${purchase_uuid}/`, {});
};

export const attachSuggestedProductToPurchase = (
  product_uuid: string | undefined,
  purchase_uuid: string
): Promise<AxiosResponse> => {
  return axiosClient.patch(`${path}suggested-product/${purchase_uuid}/`, {
    product_uuid,
  });
};

export const attachProductToPossibleProduct = (
  product_uuid: string | undefined,
  line_uuid: string
): Promise<AxiosResponse> => {
  return axiosClient.patch(`${path}product-assign/${line_uuid}/`, {
    product_uuid,
  });
};

export const getArticlesList = (
  receipt_uuid: string
): Promise<AxiosResponse> => {
  return axiosClient.get<[]>(`${path}product-assign`, {
    params: {
      receipt_uuid,
    },
  });
};

export const updatePurchase = (
  purchase_uuid: string,
  purchase: PurchaseInterface,
  product: ProductItemInterface | undefined,
  tags: TagInterface[]
): Promise<AxiosResponse> => {
  return axiosClient.patch(`${path}purchase/${purchase_uuid}/`, {
    purchase,
    product,
    tags,
  });
};

export const getConstructorDetails = (receipt_uuid: string) => {
  return axiosClient.get<ConstructorDetailsInterface>(
    `${path}constructor-details/${receipt_uuid}/`
  );
};
