import { createRouter, createWebHistory } from "vue-router";
import routes from "@/router/routes";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    const exception = [
      "AccountImportDetail",
      "AccountDetails",
      "SearchPage",
      "PeriodDetails",
      "ReceiptAcceptView",
    ];
    if (exception.includes(to.name as string)) {
      return false;
    }
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  window.document.title = `BudżetRodzinki | ${to.name as string}`;
  next();
});

export default router;
