import { defineStore } from "pinia";
import { reactive } from "vue";

export const useNavigationStore = defineStore(
  `constructor-accept-navigation-store-${Math.random()}`,
  () => {
    const isTransactionActive = reactive({
      value: false,
    });

    const activeTransactionUUID = reactive({
      value: undefined as string | undefined,
    });

    return { isTransactionActive, activeTransactionUUID };
  }
);
