import { _axios } from "@/services/api";
import { DetailsTabInterface } from "@/pages/constructor/types/ReceiptAcceptInterfaces";
import { PeriodsTabInterface } from "@/pages/period-list/types/periods-tab";

const { axiosClient } = _axios();
const path = "receipt-accept-tabs/";

export const getDetailsTab = (receipt_uuid: string) => {
  return axiosClient.get<DetailsTabInterface>(
    `${path}details-tab/${receipt_uuid}/`
  );
};

export const updateDetailsTab = (
  receipt_uuid: string,
  details_tab: DetailsTabInterface
) => {
  return axiosClient.patch(`${path}details-tab/${receipt_uuid}/`, details_tab);
};

export const updatePeriodsTab = (
  receipt_uuid: string,
  periods_tab: PeriodsTabInterface
) => {
  return axiosClient.patch(`${path}periods-tab/${receipt_uuid}/`, periods_tab);
};
