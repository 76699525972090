<template>
  <div class="transaction-skeleton--item">
    <div class="transaction-skeleton--item-top">
      <div class="transaction-skeleton--item-top-left">
        <div class="transaction-skeleton--icon"></div>
        <div class="transaction-skeleton--icon"></div>
        <div class="transaction-skeleton--icon"></div>
      </div>
      <div class="transaction-skeleton--item-top-right">
        <div class="transaction-skeleton--big-icon"></div>
        <div class="transaction-skeleton--big-icon"></div>
        <div class="transaction-skeleton--icon"></div>
      </div>
    </div>
    <div class="transaction-skeleton--item-bottom">
      <div class="transaction-skeleton--details"></div>
      <div class="transaction-skeleton--details"></div>
      <div class="transaction-skeleton--amount bolder"></div>
    </div>
  </div>
</template>

<style
  scoped
  lang="scss"
  src="@/pages/constructor-accept/components/scss/transaction-skeleton.scss"
/>
